<template>
  <div id="app">
    <transition name="fade">
      <router-view />
    </transition>
    <transition-group
      name="fade"
      v-if="toasts.length"
      class="toast--container fixed bottom--2 right--2 flex flex--column flex--end__hor"
    >
      <toast
        v-for="toast in toasts"
        :key="toast.id"
        :toastID="toast.id"
        :type="toast.type"
        :title="toast.title"
      />
    </transition-group>
  </div>
</template>

<script>
import Toast from "@/components/reusable/Toast";
export default {
  name: "app",
  components: { Toast },
  computed: {
    theme() {
      return this.$store.getters.getTheme;
    },
    toasts() {
      return this.$store.getters.getToasts;
    },
  },
  async mounted() {
    await this.$store.dispatch("retrieveErrorCodes");
  },
};
</script>

<style lang="scss">
@import "style/main";
</style>
