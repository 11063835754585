<template>
  <div
    class="absolute navBar flex flex--column overflowAuto courseNavigation"
    :class="{ 'navBar--out': mobileOut }"
  >
    <div
      v-if="course.course.thumbnail.link"
      class="thumbnailImage"
      :style="{
        'background-image': `url('${url}${course.course.thumbnail.link}')`,
      }"
    />
    <div class="navBar--outline flex--grow innerNavBar">
      <div class="navBarScrollable">
        <!-- Homepage -->
        <div class="navBar--chapter__wrapper">
          <div
            class="navBar--chapter clickable pad--2"
            @click.self="clickTitle"
            :class="{ active: !page }"
          >
            <div
              class="flex flex--space__hor font--s16 font--w700 lineHeight--1"
              @click.self="clickTitle"
            >
              <div class="flex flex--center__vert flex--space__hor width--100">
                <div class="mar--right__1" @click="clickTitle">Startseite</div>
              </div>
            </div>
          </div>
        </div>
        <!-- Course "Chapter" -->
        <div class="navBar--chapter__wrapper">
          <div
            class="navBar--chapter clickable pad--2"
            :class="{
              open: courseChapterExpanded,
            }"
            @click.self="expandCourseChapter"
          >
            <div
              class="flex flex--space__hor font--s16 font--w700 lineHeight--1"
              @click.self="expandCourseChapter"
            >
              <div class="flex flex--center__vert flex--space__hor width--100">
                <div class="mar--right__1" @click="expandCourseChapter">
                  {{ course.course.title }}
                </div>
                <i
                  class="ion-ios-arrow-forward navBar--chapter__expand flex flex--center"
                  :class="{
                    'rotate--90': courseChapterExpanded,
                  }"
                  @click.self="expandCourseChapter"
                />
              </div>
            </div>
          </div>

          <div v-if="courseChapterExpanded" class="chapterLecturesWrapper">
            <template v-for="chapter in courseDetails.course">
              <template v-if="!chapter.title.startsWith('Begleitmaterial')">
                <div
                  class="navBar--page font--s14 flex--center__vert flex--space__hor pad--2 clickable lineHeight--14"
                  v-for="page in chapter.pages"
                  :key="page._id"
                  @click.self="
                    accessible(chapter._id)
                      ? null
                      : clickPage(chapter._id, page._id)
                  "
                  :class="{
                    hide: false,
                    flex: true,
                    active: $route.params.page === page._id,
                  }"
                >
                  <div class="flex">
                    <div
                      class="navBar--page__progress flex flex--center clickable mar--right__1 courseCheckmark"
                      :class="{ 'navBar--page__done': page.done }"
                    >
                      <i
                        class="ion-md-checkmark"
                        v-if="page.done && !accessible(chapter._id)"
                      />
                      <i class="ion-md-lock" v-if="accessible(chapter._id)" />
                    </div>
                    <div
                      @click.self="
                        accessible(chapter._id)
                          ? null
                          : clickPage(chapter._id, page._id)
                      "
                    >
                      {{ page.title }}
                    </div>
                  </div>
                  <div
                    class="font--s1 navBar--page__duration flex flex--end__hor"
                  >
                    {{ page.duration ? `${page.duration} min` : "" }}
                  </div>
                </div>
              </template>
            </template>
          </div>
        </div>

        <!-- Material "Chapter" -->
        <div class="navBar--chapter__wrapper">
          <div
            class="navBar--chapter clickable pad--2"
            @click="expandMaterialChapter"
            :class="{
              open: materialChapterExpanded,
            }"
          >
            <div
              class="flex flex--space__hor font--s16 font--w700 lineHeight--1"
            >
              <div class="flex flex--center__vert flex--space__hor width--100">
                <div class="mar--right__1">Begleitmaterial</div>
                <i
                  class="ion-ios-arrow-forward navBar--chapter__expand flex flex--center"
                  :class="{
                    'rotate--90': materialChapterExpanded,
                  }"
                />
              </div>
            </div>
          </div>

          <div v-if="materialChapterExpanded" class="chapterLecturesWrapper">
            <template v-for="chapter in courseDetails.course">
              <template v-if="chapter.title.startsWith('Begleitmaterial')">
                <div
                  class="navBar--page font--s14 flex--center__vert flex--space__hor pad--2 clickable lineHeight--14"
                  v-for="page in chapter.pages"
                  :key="page._id"
                  @click.self="
                    accessible(chapter._id)
                      ? null
                      : clickPage(chapter._id, page._id)
                  "
                  :class="{
                    hide: false,
                    flex: true,
                    active: $route.params.page === page._id,
                  }"
                >
                  <div class="flex">
                    <div
                      class="navBar--page__progress flex flex--center clickable mar--right__1 courseCheckmark"
                      :class="{ 'navBar--page__done': page.done }"
                      @click="
                        accessible(chapter._id)
                          ? null
                          : toggleDone(chapter._id, page)
                      "
                    >
                      <i
                        class="ion-md-checkmark"
                        v-if="page.done && !accessible(chapter._id)"
                      />
                      <i class="ion-md-lock" v-if="accessible(chapter._id)" />
                    </div>
                    <div
                      @click.self="
                        accessible(chapter._id)
                          ? null
                          : clickPage(chapter._id, page._id)
                      "
                    >
                      {{ page.title }}
                    </div>
                  </div>
                  <div
                    class="font--s1 navBar--page__duration flex flex--end__hor"
                  >
                    {{ page.duration ? `${page.duration} min` : "" }}
                  </div>
                </div>
              </template>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "OamnCourseNavigation",
  props: ["mobileOut", "progress"],
  data() {
    return {
      expandedChapters: [],
      courseChapterExpanded: false,
      materialChapterExpanded: false,
    };
  },
  computed: {
    course() {
      return (
        this.courses.find(
          (el) => el.course._id === this.$route.params.courseID
        ) || {
          course: {
            title: "",
            chapters: [],
          },
        }
      );
    },
    courses() {
      return this.$store.getters.getCourses;
    },
    courseDetails() {
      return this.$store.getters.getCourseDetails;
    },
    page() {
      return this.$route.params.page;
    },
    url() {
      return this.$store.getters.getURL;
    },
  },
  methods: {
    accessible(id) {
      if (!this.courseDetails || this.courseDetails.fullAccess) return false;
      let chapter = this.courseDetails.course.find((el) => el._id === id);
      if (chapter) {
        return (
          chapter.timeToAccess +
            new Date(this.courseDetails.joinDate).getTime() >
          new Date()
        );
      }
      return false;
    },
    timeUntilUnlock(id) {
      let chapter = this.courseDetails.course.find((el) => el._id === id);
      if (chapter && chapter.timeToAccess != null) {
        if (
          new Date(this.courseDetails.joinDate).getTime() < new Date().getTime()
        ) {
          return Math.round(
            (chapter.timeToAccess +
              new Date(this.courseDetails.joinDate).getTime() -
              new Date().getTime()) /
              86400000
          );
        } else {
          if (chapter.timeToAccess === 0 || chapter.timeToAccess == null) {
            return Math.round(
              (new Date(this.courseDetails.joinDate).getTime() -
                new Date().getTime()) /
                86400000
            );
          } else {
            return Math.round(
              (chapter.timeToAccess +
                new Date(this.courseDetails.joinDate).getTime() -
                new Date().getTime()) /
                86400000
            );
          }
          // return Math.round(((chapter.timeToAccess + new Date().getTime()) - (new Date(this.course.joinDate).getTime())) / 86400000)
        }
      }
    },
    clickPage(chapter, page) {
      this.$emit("close");
      this.$router.push({ name: "Page", params: { chapter, page } });
    },
    clickTitle() {
      this.$emit("close");
      this.$router.push({ name: "CourseWelcome" });
    },
    clickChapterTitle(id) {
      this.$emit("close");
      this.$router.push({ name: "ChapterOverview", params: { chapter: id } });
    },
    expandCourseChapter() {
      this.courseChapterExpanded = !this.courseChapterExpanded;
    },
    expandMaterialChapter() {
      this.materialChapterExpanded = !this.materialChapterExpanded;
    },
    back() {
      if (this.$route.params.chapter) {
        this.$emit("close");
        this.$router.push({ name: "CourseWelcome" });
      } else {
        const organizationID = this.$route.params.organizationID;
        this.$router.push({
          name: "CourseOverview",
          params: { organizationID },
        });
      }
    },
    expandChapter(val) {
      if (this.expandedChapters.includes(val)) {
        this.expandedChapters = this.expandedChapters.filter(
          (el) => el !== val
        );
      } else {
        this.expandedChapters.push(val);
      }
    },
    toggleDone(chapterID, page) {
      this.$store.dispatch("setPageProgress", {
        courseID: this.$route.params.courseID,
        chapterID,
        pageID: page._id,
        status: {
          done: !page.done,
        },
      });

      this.$store.commit("setPageProgress", {
        chapterID,
        pageID: page._id,
        status: {
          done: !page.done,
        },
      });
    },
  },
  watch: {
    // courseDetails(newVal) {
    //     this.expandedChapters = newVal.course.map(el => el._id);
    // },
  },
  mounted() {
    if (this.courseDetails.course) {
      this.expandedChapters = this.courseDetails.course.map((el) => el._id);
    }
  },
};
</script>
