<template>
  <div class="flex flex--center">
    <div class="settings--wrapper">
      <div class="flex flex--column">
        <div
          class="flex font--s2 pad--left__1 lineHeight--12 clickable"
          @click="$router.go(-1)"
        >
          <i class="ion-ios-arrow-back mar--right__1" />
          <div>Zurück</div>
        </div>
        <div
          class="flex flex--space__hor flex--center__vert courses--title pad--1 mar--bottom__2"
        >
          <div class="font--s3 font--w700 lineHeight--12">Einstellungen</div>
          <ActionButton
            icon="ion-md-more"
            light="true"
            @onClick="toggleMainPopup"
            v-click-outside="closeMainPopup"
          />
        </div>
      </div>

      <div v-if="!loading">
        <div
          v-if="doguUser.clubAbo && doguUser.clubAbo !== 'none'"
          class="subscription_container"
        >
          <div class="subscription_info">
            <div class="logo">
              <img src="../../../assets/dogu_logo.png" />
            </div>
            <div class="text">
              <div class="title">Doguniversity Club-Mitgliedschaft</div>
              Hier kommst du zu deiner persönlichen Zahlungsverwaltungsseite. Du
              kannst dort selbstständig deine Mitgliedschaft verwalten oder
              deine Zahlungsmethode anpassen.
            </div>
          </div>
          <div class="subscription_actions">
            <div class="title">Abrechnung & Zahlung</div>
            <div v-if="doguUser.clubAbo === 'web'">
              <div v-if="subscription && subscription.length > 0">
                <a
                  :href="subscription[0].link"
                  style="font-size: 16px; line-height: 22px"
                  target="_blank"
                  >Abrechnung und Zahlung bearbeiten</a
                >
              </div>
              <div
                style="font-size: 16px; line-height: 23px; font-style: italic"
                v-else
              >
                Link konnte nicht geladen werden. Bitte versuche es später noch
                einmal.
              </div>
            </div>
            <div
              v-else-if="doguUser.clubAbo === 'app'"
              style="font-size: 16px; line-height: 23px"
            >
              Du hast ein App-Abonnement. Bitte verwende die App, um es zu
              verwalten
            </div>
          </div>
        </div>
        <div v-else class="no_subscription">
          Du hast keine aktive Mitgliedschaft.
        </div>
      </div>
      <div v-else>Wird geladen...</div>
    </div>
    <transition name="fade">
      <div class="absolute popup--anchor" v-if="showMainPopup">
        <PopUp :posX="posX" :posY="posY">
          <div
            class="popup--item pad--2 clickable font--s16"
            @click="toggleEditInfo"
          >
            Informationen ändern
          </div>
          <div
            class="popup--item pad--2 clickable font--s16 color--danger"
            @click="forgotPassword"
          >
            Passwort zurücksetzen
          </div>
          <!-- <div class="popup--item pad--2 clickable font--s16 color--danger">
            Account Löschen
          </div> -->
        </PopUp>
      </div>
    </transition>
    <transition name="fade">
      <Modal title="Informationen" @toggle="toggleEditInfo" v-if="showEditInfo">
        <div class="pad--25 flex flex--grow flex--column flex--center__hor">
          <label for="firstname" class="form--label">Vorname</label>
          <input
            type="text"
            id="firstname"
            class="form--input form--input__text mar--bottom__1"
            v-model="firstName"
          />
          <label for="lastname" class="form--label">Nachname</label>
          <input
            type="text"
            id="lastname"
            class="form--input form--input__text mar--bottom__1"
            v-model="lastName"
          />
          <AccentButton
            :full-width="true"
            :filled="true"
            title="Änderungen Speichern"
            ref="validate"
            tabindex="0"
            :loading="loading"
            @onClick="updateUser"
          />
        </div>
      </Modal>
    </transition>
  </div>
</template>

<style>
.no_subscription {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 8px;
}
.subscription_container {
  display: flex;
  flex-direction: row;
  gap: 96px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 32px;
  }
}
.subscription_info,
.subscription_actions {
  flex: 1;
}
.subscription_info {
  display: flex;
  gap: 32px;
}
.subscription_info .logo {
}
.subscription_info .text {
  flex: 1;
  font-size: 16px;
  line-height: 23px;
}
.title {
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 8px;
}
</style>

<script>
import AccentButton from "@/components/reusable/AccentButton";
import Modal from "@/components/reusable/Modal";
import PopUp from "@/components/reusable/PopUp";
import ActionButton from "@/components/reusable/ActionButton";
export default {
  name: "SettingsLoader",
  components: { ActionButton, PopUp, Modal, AccentButton },
  data() {
    return {
      posX: null,
      posY: null,

      showMainPopup: false,
      showEditInfo: false,

      firstName: "",
      lastName: "",
      email: "",

      loading: true,
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    doguUser() {
      return this.$store.getters.getDoguUser;
    },
    subscription() {
      return this.$store.getters.getSubscription;
    },
  },
  methods: {
    userHasSubscription() {
      return this.user.clubAbo && this.user.clubAbo !== "none";
    },
    toggleMainPopup(e) {
      this.posX = e.clientX;
      this.posY = e.clientY;

      this.showMainPopup = !this.showMainPopup;
    },
    closeMainPopup() {
      this.showMainPopup = false;
    },
    toggleEditInfo() {
      this.showEditInfo = !this.showEditInfo;
    },
    forgotPassword() {
      this.$store
        .dispatch("forgotPassword", {
          organizationID: this.$route.params.organizationID,
          email: this.user.email,
        })
        .then(() => {
          this.$store.commit("addToast", {
            type: "success",
            title: "Schau in deine Mails um das Passwort zurückzusetzen.",
          });
        })
        .catch(() => {
          this.$store.commit("addToast", {
            type: "warning",
            title: "Das Passwort konnte nicht zurückgesetzt werden",
          });
        });
    },
    async deleteAccount() {
      await this.$store.dispatch("deleteUser").then(() => {
        this.$router.push(`/${this.$route.params.organizationID}/login`);
      });
    },
    async updateUser() {
      await this.$store.dispatch("updateOwnUser", {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
      });
      this.showEditInfo = false;
    },
  },
  async mounted() {
    await this.$store.dispatch("retrieveOwnUser");
    this.firstName = this.user.firstName;
    this.lastName = this.user.lastName;
    this.email = this.user.email;

    await this.$store.dispatch("retrieveDoguOwnUser").then(() => {
      this.loading = false;
    });

    await this.$store.dispatch(
      "retrieveSubscription",
      this.$route.params.organizationID
    );
  },
};
</script>
