<template>
  <div
    class="flex flex--column flex--center fullScreen"
    :style="{ '--primary-color': theme.primary || '#114B5F' }"
  >
    <div class="flex flex--column footer--contet flex--center__vert pad--4">
      <img src="../../../assets/SVG6.svg" class="fallback--image" />
      <!--            <img src="../../../assets/SVG3.svg" class="fallback&#45;&#45;logo" />-->
      <div class="fallback--title">Ooops..</div>
      <div class="font--s2 font--center mar--bottom__2">
        Leider konnten wir nichts zu der eingegebenen Adresse finden. <br />
        Bitte versichere dich nochmal, dass du den korrekten Link eingegeben
        hast.
      </div>
      <div class="flex">
        <a href="https://okourse.com" class="noDec">
          <AccentButton title="Zu OKourse.com" filled="true" />
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import AccentButton from "@/components/reusable/AccentButton";
export default {
  name: "Fallback",
  components: { AccentButton },
  computed: {
    theme() {
      return this.$store.getters.getTheme;
    },
  },
  mounted() {
    this.$store.commit("setTheme", {
      actions: [],
      primary: "#114B5F",
    });
  },
};
</script>
