<template>
  <div class="flex flex--column flex--center">
    <div class="flex flex--column flex--center__vert courses--wrapper">
      <div class="flex flex--column font--s2">
        <div class="font--s5 font--w700 lineHeight--12 mar--top__4">
          Impressum
        </div>
        <div class="font--w700 font--s25 lineHeight--12 mar--bottom__4">
          Angaben gemäß §5 TMG
        </div>

        <div class="font--w700">NEON Software Solutions GmbH</div>
        <div class="lineHeight--12">Burgsdorfstr. 5</div>
        <div class="lineHeight--12">13353 Berlin</div>
        <div class="lineHeight--12">Deutschland</div>
        <div class="lineHeight--12 mar--bottom__2">+49 152 56457016</div>

        <div class="lineHeight--12">St.-IdNr: DE327944700</div>
        <div class="lineHeight--12 mar--bottom__4">
          Registernummer: HRB: 213873 B | Amtsgericht Charlottenburg
        </div>

        <div class="font--w700">Geschäftsführung:</div>
        <div class="lineHeight--12 mar--bottom__2">
          Niklas Stemberg, Leon Stephan
        </div>

        <div class="font--w700">Kontakt:</div>
        <div class="lineHeight--12">E-Mail: hello@neon.dev</div>
        <div class="lineHeight--12 mar--bottom__4">Tel: +49 152 56457016</div>

        <div class="font--s3 font--w700 mar--bottom__1">Disclaimer</div>
        <div class="font--s25 font--w700">Haftung für Links</div>
        <div class="mar--bottom__2">
          Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren
          Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
          fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
          verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der
          Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
          Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige
          Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine
          permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
          konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
          Bekanntwerden von Rechtsverletzungen werden wir derartige Links
          umgehend entfernen.
        </div>
        <div class="font--s25 font--w700">Urheberrecht</div>
        <div class="mar--bottom__4">
          Die Inhalte dieser Website unterliegen dem Urheberrecht, sofern nicht
          anders gekennzeichnet, und dürfen nicht ohne vorherige schriftliche
          Zustimmung von NEON Software Solutions weder als Ganzes noch in Teilen
          verbreitet, verändert oder kopiert werden. Die auf dieser Website
          eingebundenen Bilder dürfen nicht ohne vorherige schriftliche
          Zustimmung von NEON Software Solutions verwendet werden. Auf den
          Websites enthaltene Bilder unterliegen teilweise dem Urheberrecht
          Dritter. Soweit die Inhalte auf dieser Seite nicht vom Betreiber
          erstellt wurden, werden die Urheberrechte Dritter beachtet.
          Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten
          Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden,
          bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
          Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from "@/components/reusable/Footer";
export default {
  name: "Imprint",
  components: { Footer },
};
</script>
