export default {
  token:
    localStorage.getItem("x-auth-token") &&
    localStorage.getItem("x-auth-token") != "null"
      ? localStorage.getItem("x-auth-token")
      : sessionStorage.getItem("x-auth-token"),
  doguToken:
    localStorage.getItem("x-auth-token-dogu") &&
    localStorage.getItem("x-auth-token-dogu") != "null"
      ? localStorage.getItem("x-auth-token-dogu")
      : sessionStorage.getItem("x-auth-token-dogu"),
  doguUser: {},
  URL: process.env.VUE_APP_DOMAIN_BACKEND + "/",
  DoguBackend: process.env.VUE_APP_DOGU_DOMAIN + "/",
  // URL: window.location.href.split('/')[2] !== 'member.okourse.com' ? 'https://api.' + window.location.href.split('/')[2] + '/' : 'https://api.okourse.com/',
  user: {},
  theme: {
    actions: [],
    libraryLayout: "list",
    primary: "#114B5F",
  },
  title: "OKourse",
  toasts: [],
  courses: null,
  courseDetails: [],
  organizationID: null,
  errorCodes: null,
  subscription: null,
  coopPartners: null,
  coop: null,
  coupon: null,
  coopRecord: null,
  loginRes: null,
  doguLoginRes: null,
};
