/* eslint-disable */
export default {
  getToken: (state) => state.token,
  getURL: (state) => state.URL,
  getDoguToken: (state) => state.doguToken,
  getDoguUser: (state) => state.doguUser,
  getDoguBackendUrl: (state) => state.DoguBackend,
  getToasts: (state) => state.toasts,
  getUser: (state) => state.user,
  getTheme: (state) => state.theme,
  getTitle: (state) => state.title,
  getCourses: (state) => state.courses,
  getCourseDetails: (state) => state.courseDetails,
  getOrganizationID: (state) => state.organizationID,
  getErrorMsg: (state) => (statusCode, errorCode) => {
    return state.errorCodes[statusCode].find((el) => el.code === errorCode).msg;
  },
  getSubscription: (state) => state.subscription,
  getCoopPartners: (state) => state.coopPartners,
  getCoop: (state) => state.coop,
  getCoupon: (state) => state.coupon,
  getCoopRecord: (state) => state.coopRecord,
  getLoginRes: (state) => state.loginRes,
  getDoguLoginRes: (state) => state.doguLoginRes,
};
