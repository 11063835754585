<template>
  <div></div>
</template>
<script>
import store from "../../store";
export default {
  name: "OrgLoader",
  async beforeRouteEnter(to, from, next) {
    /* eslint-disable */

    if (window.location.href.includes("localhost")) {
      next("/fallback");
      return;
    } else if (window.location.href.includes("member.okourse")) {
      const id = window.location.href.split("/")[3];
      if (id) {
        next("/" + id);
        return;
      } else {
        next("/fallback");
        return;
      }
    }

    const isOkourse = window.location.href.split(".")[1] === "okourse";
    let domainName;
    if (isOkourse) {
      domainName = window.location.href.split(".")[0].split("//")[1];
    } else {
      domainName = window.location.href.split("/")[2];
    }
    const orgID = await store.dispatch("fetchOrganization", domainName);
    if (orgID) {
      next("/" + orgID);
    } else {
      next("/fallback");
    }

    // const firstPath = to.path.split('/')[1];
    // if (window.location.href.split('/')[2] === 'localhost:8081' && !firstPath) {
    // // if (window.location.href.split('/')[2] === 'member.okourse.com' && !firstPath) {
    //     next('/fallback');
    // } else if (window.location.href.split('/')[2] !== 'localhost:8081' && !firstPath) {
    // // } else if (window.location.href.split('/')[2] !== 'member.okourse.com' && !firstPath) {
    //     const orgID = await store.dispatch('fetchOrganization', window.location.href.split('/')[2]);
    //     if (orgID) {
    //         next('/' + orgID);
    //     } else {
    //         next('/fallback');
    //     }
    // }
  },
};
</script>
