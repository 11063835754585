<template>
  <div ref="player" class="audioPlayer">
    <audio ref="audio" :src="file" preload="metadata" />
    <button class="playButton" ref="playButton">
      <i class="ion-md-play" v-if="!playing" />
      <i class="ion-md-pause" v-if="playing" />
    </button>

    <div class="sliderContainer">
      <div class="title">
        {{ name }}
        <div style="float: right">
          <span ref="currentTime">00:00</span>
          /
          <span ref="duration">00:00</span>
        </div>
      </div>
      <input type="range" min="0" max="100" value="0" ref="slider" />
    </div>
    <a class="noDec" :href="file">
      <i class="ion-md-download" />
    </a>
  </div>
</template>
<script>
export default {
  name: "AccentButton",
  props: ["name", "file"],
  data() {
    return {
      playing: false,
    };
  },
  methods: {
    calculateTime(secs) {
      const minutes = Math.floor(secs / 60);
      const seconds = Math.floor(secs % 60);
      const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
      return `${minutes}:${returnedSeconds}`;
    },
  },
  mounted() {
    let raf = null;
    let slider = this.$refs.slider;
    let audio = this.$refs.audio;
    let currentTime = this.$refs.currentTime;
    let player = this.$refs.player;

    const calculateTime = (secs) => {
      const minutes = Math.floor(secs / 60);
      const seconds = Math.floor(secs % 60);
      const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
      return `${minutes}:${returnedSeconds}`;
    };

    const whilePlaying = () => {
      if (
        Math.floor(this.$refs.slider.value) !=
        Math.floor(this.$refs.audio.currentTime)
      ) {
        this.$refs.slider.value = Math.floor(this.$refs.audio.currentTime);
        this.$refs.currentTime.textContent = calculateTime(
          Math.floor(this.$refs.audio.currentTime)
        );
        this.$refs.player.style.setProperty(
          "--seek-before-width",
          (slider.value / slider.max) * 100 + "%"
        );
      }

      //currentTimeContainer.textContent = calculateTime(seekSlider.value);
      //audioPlayerContainer.style.setProperty('--seek-before-width', `${seekSlider.value / seekSlider.max * 100}%`);

      raf = requestAnimationFrame(whilePlaying);
    };

    this.$refs.playButton.addEventListener("click", () => {
      // eslint-disable-next-line no-console
      if (!this.playing) {
        this.$refs.audio.play();
        requestAnimationFrame(whilePlaying);
        this.playing = true;

        // this.playing = true;
        // requestAnimationFrame(whilePlaying);
        // this.$refs.audio.play();
      } else {
        this.$refs.audio.pause();
        cancelAnimationFrame(raf);
        this.playing = false;
      }
    });

    //this.$refs.pauseButton.addEventListener("click", () => {});

    this.$refs.audio.addEventListener("loadeddata", () => {
      this.$refs.duration.textContent = calculateTime(
        this.$refs.audio.duration
      );
      this.$refs.slider.max = Math.floor(this.$refs.audio.duration);
    });

    this.$refs.audio.addEventListener("ended", function () {
      // eslint-disable-next-line no-console
      // ended
    });

    this.$refs.slider.addEventListener("input", function () {
      currentTime.textContent = calculateTime(slider.value);
      if (!audio.paused) {
        cancelAnimationFrame(raf);
      }
      player.style.setProperty(
        "--seek-before-width",
        (slider.value / slider.max) * 100 + "%"
      );
    });

    this.$refs.slider.addEventListener("change", () => {
      this.$refs.audio.currentTime = this.$refs.slider.value;
      if (!this.$refs.audio.paused) {
        requestAnimationFrame(whilePlaying);
      }
    });
  },
};
</script>
