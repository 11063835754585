<template>
  <div
    :class="{ 'toast--out': !isVisible }"
    class="toast relative b-radius--5 flex flex--center__vert mar--top__1 pad--2"
  >
    <div
      class="toast--icon color--success mar--right__1"
      v-if="this.type === 'success'"
    >
      <i class="ion-ios-checkmark-circle-outline" />
    </div>
    <div
      class="toast--icon color--warning mar--right__1"
      v-if="this.type === 'warning'"
    >
      <i class="ion-ios-alert" />
    </div>
    <div
      class="toast--icon color--danger mar--right__1"
      v-if="this.type === 'error'"
    >
      <i class="ion-ios-close-circle-outline" />
    </div>
    <div class="flex flex--column">
      <div class="font--s16 font--w700 lineHeight--12">
        {{ this.title || "Fehler" }}
      </div>
      <div
        @click="closeToast"
        class="absolute clickable top--0 right--1 font--s2"
      >
        <i class="ion-md-close" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Toast",
  props: ["type", "title", "message", "toastID"],
  data() {
    return {
      isVisible: true,
      timeout: null,
    };
  },
  methods: {
    closeToast() {
      this.isVisible = false;
      setTimeout(() => {
        this.$store.commit("deleteToast", this.toastID);
      }, 600);
    },
  },
  mounted() {
    setTimeout(() => {
      this.closeToast();
    }, 5000);
  },
};
</script>
