<template>
  <div class="width--100 flex flex--column">
    <div class="flex content--header">
      <div
        class="video--move flex flex--center lineHeight--1 clickable"
        @click="prevPage"
      >
        <i class="ion-ios-arrow-back" />
      </div>
      <div
        class="flex--grow video--details flex flex--end__hor flex--center__vert"
      >
        <AccentButton
          :title="
            accessible(chapter._id) ? 'Noch nicht verfügbar' : 'Los geht\'s'
          "
          @onClick="accessible(chapter._id) ? null : nextPage()"
          :disabled="accessible(chapter._id)"
        />
      </div>
      <div
        class="video--move flex flex--center lineHeight--1 clickable"
        @click="accessible(chapter._id) ? null : nextPage()"
      >
        <i class="ion-ios-arrow-forward" />
      </div>
    </div>
    <div class="flex flex--center__hor content--title width--100">
      <div class="font--s3 font--w700">Kapitel: {{ chapter.title }}</div>
    </div>
    <div class="flex flex--column pad--3">
      <div
        class="card bgColor--white b-radius--5 mar--bottom__1 bgColor--white"
      >
        <div class="navBar--chapter clickable pad--2">
          <div class="flex flex--space__hor font--s16 font--w700 lineHeight--1">
            <div class="">
              {{ chapter.title }}
            </div>
          </div>
        </div>
        <div
          class="navBar--page font--s14 flex flex--center__vert flex--space__hor pad--2 clickable lineHeight--14"
          v-for="page in chapter.pages"
          :key="page._id"
          @click.self="
            accessible(chapter._id) ? null : clickPage(chapter._id, page._id)
          "
        >
          <div
            class="flex"
            :class="{
              'color--primary': $route.params.page === page._id,
              'font--w700': $route.params.page === page._id,
            }"
          >
            <div
              class="navBar--page__progress flex flex--center clickable mar--right__1"
              :class="{ 'navBar--page__done': page.done }"
              @click="toggleDone(chapter._id, page)"
            >
              <i
                class="ion-md-checkmark"
                v-if="page.done && !accessible(chapter._id)"
              />
              <i class="ion-md-lock" v-if="accessible(chapter._id)" />
            </div>
            <div
              @click.self="
                accessible(chapter._id)
                  ? null
                  : clickPage(chapter._id, page._id)
              "
            >
              {{ page.title }}
            </div>
          </div>
          <div class="font--s1">
            {{ page.duration ? `${page.duration} min` : "" }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AccentButton from "@/components/reusable/AccentButton";
export default {
  name: "ChapterOverview",
  components: { AccentButton },
  computed: {
    chapter() {
      if (this.$store.getters.getCourseDetails.course) {
        return (
          this.$store.getters.getCourseDetails.course.find(
            (el) => el._id === this.$route.params.chapter
          ) || { pages: [] }
        );
      }
      return { pages: [] };
    },
    courseDetails() {
      return this.$store.getters.getCourseDetails;
    },
    course() {
      return (
        this.courses.find(
          (el) => el.course._id === this.$route.params.courseID
        ) || {
          course: {
            title: "",
            chapters: [],
          },
        }
      );
    },
    courses() {
      return this.$store.getters.getCourses;
    },
  },
  methods: {
    nextPage() {
      const chapterInd = this.courseDetails.course.findIndex(
        (el) => el._id === this.chapter._id
      );
      this.$router.push({
        name: "Page",
        params: {
          chapter: this.courseDetails.course[chapterInd]._id,
          page: this.courseDetails.course[chapterInd].pages[0]._id,
        },
      });
    },
    prevPage() {
      const chapterInd = this.courseDetails.course.findIndex(
        (el) => el._id === this.chapter._id
      );
      if (chapterInd <= 0) {
        this.$router.push({
          name: "CourseWelcome",
        });
      } else {
        this.$router.push({
          name: "Page",
          params: {
            chapter: this.courseDetails.course[chapterInd - 1]._id,
            page: this.courseDetails.course[chapterInd - 1].pages[
              this.courseDetails.course[chapterInd - 1].pages.length - 1
            ]._id,
          },
        });
      }
    },
    accessible(id) {
      if (!this.courseDetails || this.course.fullAccess) return false;
      let chapter = this.courseDetails.course.find((el) => el._id === id);
      if (chapter) {
        return (
          chapter.timeToAccess +
            new Date(this.courseDetails.joinDate).getTime() >
          new Date()
        );
      }
      return false;
    },
    clickPage(chapter, page) {
      this.$router.push({ name: "Page", params: { chapter, page } });
    },
  },
};
</script>
