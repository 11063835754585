var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"absolute navBar flex flex--column overflowAuto courseNavigation",class:{ 'navBar--out': _vm.mobileOut }},[(_vm.course.course.thumbnail.link)?_c('div',{staticClass:"thumbnailImage",style:({
      'background-image': ("url('" + _vm.url + (_vm.course.course.thumbnail.link) + "')"),
    })}):_vm._e(),_c('div',{staticClass:"navBar--outline flex--grow innerNavBar"},[_c('div',{staticClass:"navBarScrollable"},[_c('div',{staticClass:"navBar--chapter__wrapper"},[_c('div',{staticClass:"navBar--chapter clickable pad--2",class:{ active: !_vm.page },on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.clickTitle($event)}}},[_c('div',{staticClass:"flex flex--space__hor font--s16 font--w700 lineHeight--1",on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.clickTitle($event)}}},[_c('div',{staticClass:"flex flex--center__vert flex--space__hor width--100"},[_c('div',{staticClass:"mar--right__1",on:{"click":_vm.clickTitle}},[_vm._v("Startseite")])])])])]),_c('div',{staticClass:"navBar--chapter__wrapper"},[_c('div',{staticClass:"navBar--chapter clickable pad--2",class:{
            open: _vm.courseChapterExpanded,
          },on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.expandCourseChapter($event)}}},[_c('div',{staticClass:"flex flex--space__hor font--s16 font--w700 lineHeight--1",on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.expandCourseChapter($event)}}},[_c('div',{staticClass:"flex flex--center__vert flex--space__hor width--100"},[_c('div',{staticClass:"mar--right__1",on:{"click":_vm.expandCourseChapter}},[_vm._v(" "+_vm._s(_vm.course.course.title)+" ")]),_c('i',{staticClass:"ion-ios-arrow-forward navBar--chapter__expand flex flex--center",class:{
                  'rotate--90': _vm.courseChapterExpanded,
                },on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.expandCourseChapter($event)}}})])])]),(_vm.courseChapterExpanded)?_c('div',{staticClass:"chapterLecturesWrapper"},[_vm._l((_vm.courseDetails.course),function(chapter){return [(!chapter.title.startsWith('Begleitmaterial'))?_vm._l((chapter.pages),function(page){return _c('div',{key:page._id,staticClass:"navBar--page font--s14 flex--center__vert flex--space__hor pad--2 clickable lineHeight--14",class:{
                  hide: false,
                  flex: true,
                  active: _vm.$route.params.page === page._id,
                },on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }_vm.accessible(chapter._id)
                    ? null
                    : _vm.clickPage(chapter._id, page._id)}}},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"navBar--page__progress flex flex--center clickable mar--right__1 courseCheckmark",class:{ 'navBar--page__done': page.done }},[(page.done && !_vm.accessible(chapter._id))?_c('i',{staticClass:"ion-md-checkmark"}):_vm._e(),(_vm.accessible(chapter._id))?_c('i',{staticClass:"ion-md-lock"}):_vm._e()]),_c('div',{on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }_vm.accessible(chapter._id)
                        ? null
                        : _vm.clickPage(chapter._id, page._id)}}},[_vm._v(" "+_vm._s(page.title)+" ")])]),_c('div',{staticClass:"font--s1 navBar--page__duration flex flex--end__hor"},[_vm._v(" "+_vm._s(page.duration ? ((page.duration) + " min") : "")+" ")])])}):_vm._e()]})],2):_vm._e()]),_c('div',{staticClass:"navBar--chapter__wrapper"},[_c('div',{staticClass:"navBar--chapter clickable pad--2",class:{
            open: _vm.materialChapterExpanded,
          },on:{"click":_vm.expandMaterialChapter}},[_c('div',{staticClass:"flex flex--space__hor font--s16 font--w700 lineHeight--1"},[_c('div',{staticClass:"flex flex--center__vert flex--space__hor width--100"},[_c('div',{staticClass:"mar--right__1"},[_vm._v("Begleitmaterial")]),_c('i',{staticClass:"ion-ios-arrow-forward navBar--chapter__expand flex flex--center",class:{
                  'rotate--90': _vm.materialChapterExpanded,
                }})])])]),(_vm.materialChapterExpanded)?_c('div',{staticClass:"chapterLecturesWrapper"},[_vm._l((_vm.courseDetails.course),function(chapter){return [(chapter.title.startsWith('Begleitmaterial'))?_vm._l((chapter.pages),function(page){return _c('div',{key:page._id,staticClass:"navBar--page font--s14 flex--center__vert flex--space__hor pad--2 clickable lineHeight--14",class:{
                  hide: false,
                  flex: true,
                  active: _vm.$route.params.page === page._id,
                },on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }_vm.accessible(chapter._id)
                    ? null
                    : _vm.clickPage(chapter._id, page._id)}}},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"navBar--page__progress flex flex--center clickable mar--right__1 courseCheckmark",class:{ 'navBar--page__done': page.done },on:{"click":function($event){_vm.accessible(chapter._id)
                        ? null
                        : _vm.toggleDone(chapter._id, page)}}},[(page.done && !_vm.accessible(chapter._id))?_c('i',{staticClass:"ion-md-checkmark"}):_vm._e(),(_vm.accessible(chapter._id))?_c('i',{staticClass:"ion-md-lock"}):_vm._e()]),_c('div',{on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }_vm.accessible(chapter._id)
                        ? null
                        : _vm.clickPage(chapter._id, page._id)}}},[_vm._v(" "+_vm._s(page.title)+" ")])]),_c('div',{staticClass:"font--s1 navBar--page__duration flex flex--end__hor"},[_vm._v(" "+_vm._s(page.duration ? ((page.duration) + " min") : "")+" ")])])}):_vm._e()]})],2):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }