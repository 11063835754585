<template>
  <div class="flex flex--column flex--center">
    <div class="courses--wrapper flex flex--column flex--center__vert">
      <div class="flex flex--column font--s16">
        <h1 class="mar--top__2">
          <strong>Allgemeine Gesch&auml;ftsbedingungen</strong>
        </h1>
        <h2><strong>1.&nbsp;Geltungsbereich</strong></h2>
        <p>
          Für alle Bestellungen über unseren Online-Shop durch Verbraucher und
          Unternehmer gelten die nachfolgenden AGB.
        </p>
        <p>
          Verbraucher ist jede natürliche Person, die ein Rechtsgeschäft zu
          Zwecken abschließt, die überwiegend weder ihrer gewerblichen noch
          ihrer selbständigen beruflichen Tätigkeit zugerechnet werden können.
          Unternehmer ist eine natürliche oder juristische Person oder eine
          rechtsfähige Personengesellschaft, die bei Abschluss eines
          Rechtsgeschäfts in Ausübung ihrer gewerblichen oder selbständigen
          beruflichen Tätigkeit handelt.
        </p>
        <p>
          Gegenüber Unternehmern gilt: Verwendet der Unternehmer
          entgegenstehende oder ergänzende Allgemeine Geschäftsbedingungen, wird
          deren Geltung hiermit widersprochen; sie werden nur dann
          Vertragsbestandteil, wenn wir dem ausdrücklich zugestimmt haben.
        </p>
        <h2 class="mar--top__2">
          <strong>2.&nbsp;Vertragspartner, Vertragsschluss</strong>
        </h2>
        <p>Der Kaufvertrag kommt zustande mit NEON Software Solutions GmbH.</p>
        <p>
          Mit Einstellung der Produkte in den Online-Shop geben wir ein
          verbindliches Angebot zum Vertragsschluss &uuml;ber diese Artikel ab.
          Sie k&ouml;nnen unsere Produkte zun&auml;chst unverbindlich in den
          Warenkorb legen und Ihre Eingaben vor Absenden Ihrer verbindlichen
          Bestellung jederzeit korrigieren, indem Sie die hierf&uuml;r im
          Bestellablauf vorgesehenen und erl&auml;uterten Korrekturhilfen
          nutzen. Der Vertrag kommt zustande, indem Sie durch Anklicken des
          Bestellbuttons das Angebot &uuml;ber die im Warenkorb enthaltenen
          Waren annehmen. Unmittelbar nach dem Absenden der Bestellung erhalten
          Sie noch einmal eine Best&auml;tigung per E-Mail.
        </p>
        <h2 class="mar--top__2">
          <strong>3.&nbsp;Vertragssprache, Vertragstextspeicherung</strong>
        </h2>
        <p>
          Die f&uuml;r den Vertragsschluss zur Verf&uuml;gung stehende(n)
          Sprache(n): Deutsch
        </p>
        <p>Der Vertragstext wird von uns nicht gespeichert.</p>
        <h2 class="mar--top__2"><strong>4.&nbsp;Lieferbedingungen</strong></h2>
        <p>Wir liefern innerhalb Deutschlands versandkostenfrei.</p>
        <p>
          Wir liefern nur im Versandweg. Eine Selbstabholung der Ware ist leider
          nicht m&ouml;glich.
        </p>
        <p>Wir liefern nicht an Packstationen.</p>
        <h2 class="mar--top__2"><strong>5.&nbsp;Bezahlung</strong></h2>
        <p>
          In unserem Shop stehen Ihnen grunds&auml;tzlich die folgenden
          Zahlungsarten zur Verf&uuml;gung:
        </p>
        <p>
          <strong>Kreditkarte</strong><br />
          Mit Abgabe der Bestellung geben Sie Ihre Kreditkartendaten an. Nach
          Ihrer Legitimation als rechtm&auml;&szlig;iger Karteninhaber wird die
          Zahlungstransaktion automatisch durchgef&uuml;hrt und Ihre Karte
          belastet.
        </p>
        <h2 class="mar--top__2"><strong>6.&nbsp;Widerrufsrecht</strong></h2>
        <p>
          Verbrauchern steht das gesetzliche Widerrufsrecht, wie in der
          Widerrufsbelehrung beschrieben, zu. Unternehmern wird kein
          freiwilliges Widerrufsrecht einger&auml;umt.
        </p>
        <h2 class="mar--top__2"><strong>7.&nbsp;Eigentumsvorbehalt</strong></h2>
        <p>
          Die Ware bleibt bis zur vollst&auml;ndigen Bezahlung unser Eigentum.
          <br />
          F&uuml;r Unternehmer gilt erg&auml;nzend: Wir behalten uns das
          Eigentum an der Ware bis zur vollst&auml;ndigen Begleichung aller
          Forderungen aus einer laufenden Gesch&auml;ftsbeziehung vor. Sie
          d&uuml;rfen die Vorbehaltsware im ordentlichen Gesch&auml;ftsbetrieb
          weiterver&auml;u&szlig;ern; s&auml;mtliche aus diesem Weiterverkauf
          entstehenden Forderungen treten Sie &ndash; unabh&auml;ngig von einer
          Verbindung oder Vermischung der Vorbehaltsware mit einer neuen Sache -
          in H&ouml;he des Rechnungsbetrages an uns im Voraus ab, und wir nehmen
          diese Abtretung an. Sie bleiben zur Einziehung der Forderungen
          erm&auml;chtigt, wir d&uuml;rfen Forderungen jedoch auch selbst
          einziehen, soweit Sie Ihren Zahlungsverpflichtungen nicht nachkommen.
        </p>
        <h2 class="mar--top__2">
          <strong>8.&nbsp;Gew&auml;hrleistung und Garantien</strong>
        </h2>
        <p>
          Es gilt das gesetzliche M&auml;ngelhaftungsrecht. Informationen zu
          gegebenenfalls geltenden zus&auml;tzlichen Garantien und deren genaue
          Bedingungen finden Sie jeweils beim Produkt und auf besonderen
          Informationsseiten im Online-Shop.
        </p>
        <p>
          Kundendienst: Sie können den Kundendienst unter hello@neon.dev
          erreichen.
        </p>
        <h2 class="mar--top__2"><strong>9.&nbsp;Streitbeilegung</strong></h2>
        <p>
          Die Europ&auml;ische Kommission stellt eine Plattform zur
          Online-Streitbeilegung (OS) bereit, die Sie
          <a href="https://ec.europa.eu/consumers/odr/" target="_blank">hier</a>
          finden. Zur Teilnahme an einem Streitbeilegungsverfahren vor einer
          Verbraucherschlichtungsstelle sind wir nicht verpflichtet und nicht
          bereit.
        </p>
        <h2 class="mar--top__2">
          <strong>10.&nbsp;Schlussbestimmungen</strong>
        </h2>
        <p>
          Sind Sie Unternehmer, dann gilt deutsches Recht unter Ausschluss des
          UN-Kaufrechts.
        </p>
        <p>
          Sind Sie Kaufmann im Sinne des Handelsgesetzbuches, juristische Person
          des &ouml;ffentlichen Rechts oder &ouml;ffentlich-rechtliches
          Sonderverm&ouml;gen, ist ausschlie&szlig;licher Gerichtsstand f&uuml;r
          alle Streitigkeiten aus Vertragsverh&auml;ltnissen zwischen uns und
          Ihnen unser Gesch&auml;ftssitz.
        </p>
        <br />
        <p>
          <i
            ><a
              target="_blank"
              title="AGB"
              href="https://shop.trustedshops.com/de/rechtstexte/"
              >AGB</a
            >
            erstellt mit dem
            <a
              target="_blank"
              title="Trusted Shops"
              href="https://shop.trustedshops.com/de/"
              >Trusted Shops</a
            >
            Rechtstexter in Kooperation mit
            <a
              target="_blank"
              title="FÖHLISCH Rechtsanwälte"
              href="https://foehlisch.com"
              rel="nofollow"
              >FÖHLISCH Rechtsanwälte</a
            >.</i
          >
        </p>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from "@/components/reusable/Footer";
export default {
  name: "AGB",
  components: { Footer },
};
</script>
