<template>
  <div class="flex flex--center">
    <div class="settings--wrapper">
      <div class="flex flex--column">
        <div
          class="flex font--s2 pad--left__1 lineHeight--12 clickable"
          @click="$router.go(-1)"
        >
          <i class="ion-ios-arrow-back mar--right__1" />
          <div>Zurück</div>
        </div>
        <div
          class="flex flex--space__hor flex--center__vert courses--title pad--1 mar--bottom__2"
        >
          <div class="font--s3 font--w700 lineHeight--12">Einstellungen</div>
          <ActionButton
            icon="ion-md-more"
            light="true"
            @onClick="toggleMainPopup"
            v-click-outside="closeMainPopup"
          />
        </div>
      </div>
      <div class="flex flex--column">
        <div class="flex pad--2 font--s2">
          <div class="mar--right__2">Vorname:</div>
          <div class="font--w700">
            {{ user.firstName }}
          </div>
        </div>
        <div class="flex pad--2 font--s2">
          <div class="mar--right__2">Nachname:</div>
          <div class="font--w700">
            {{ user.lastName }}
          </div>
        </div>
        <div class="flex pad--2 font--s2">
          <div class="mar--right__2">E-Mail:</div>
          <div class="font--w700">
            {{ user.email }}
          </div>
        </div>
        <div class="flex pad--2 font--s2">
          <div class="mar--right__2">Passwort:</div>
          <div class="font--w700">**********</div>
        </div>
        <a
          v-if="this.organizationID === DOGU"
          href="settings/subscription"
          class="flex pad--2 font--s2 noDec clickable"
          >Käufe & Abonnements</a
        >
        <a
          href="#uc-corner-modal-show"
          class="flex pad--2 font--s2 noDec clickable"
          >Cookie-Einstellungen</a
        >
      </div>
    </div>
    <transition name="fade">
      <div class="absolute popup--anchor" v-if="showMainPopup">
        <PopUp :posX="posX" :posY="posY">
          <div
            class="popup--item pad--2 clickable font--s16"
            @click="toggleEditInfo"
          >
            Informationen ändern
          </div>
          <div
            class="popup--item pad--2 clickable font--s16 color--danger"
            @click="forgotPassword"
          >
            Passwort zurücksetzen
          </div>
          <!-- <div class="popup--item pad--2 clickable font--s16 color--danger">
            Account Löschen
          </div> -->
        </PopUp>
      </div>
    </transition>
    <transition name="fade">
      <Modal title="Informationen" @toggle="toggleEditInfo" v-if="showEditInfo">
        <div class="pad--25 flex flex--grow flex--column flex--center__hor">
          <label for="firstname" class="form--label">Vorname</label>
          <input
            type="text"
            id="firstname"
            class="form--input form--input__text mar--bottom__1"
            v-model="firstName"
          />
          <label for="lastname" class="form--label">Nachname</label>
          <input
            type="text"
            id="lastname"
            class="form--input form--input__text mar--bottom__1"
            v-model="lastName"
          />
          <label for="email" class="form--label">Email</label>
          <input
            type="text"
            id="email"
            class="form--input form--input__text mar--bottom__2"
            v-model="email"
          />
          <AccentButton
            :full-width="true"
            :filled="true"
            title="Änderungen Speichern"
            ref="validate"
            tabindex="0"
            :loading="loading"
            @onClick="updateUser"
          />
        </div>
      </Modal>
    </transition>
  </div>
</template>
<script>
import AccentButton from "@/components/reusable/AccentButton";
import Modal from "@/components/reusable/Modal";
import PopUp from "@/components/reusable/PopUp";
import ActionButton from "@/components/reusable/ActionButton";
export default {
  name: "SettingsLoader",
  components: { ActionButton, PopUp, Modal, AccentButton },
  data() {
    return {
      posX: null,
      posY: null,

      showMainPopup: false,
      showEditInfo: false,

      firstName: "",
      lastName: "",
      email: "",

      loading: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    toggleMainPopup(e) {
      this.posX = e.clientX;
      this.posY = e.clientY;

      this.showMainPopup = !this.showMainPopup;
    },
    closeMainPopup() {
      this.showMainPopup = false;
    },
    toggleEditInfo() {
      this.showEditInfo = !this.showEditInfo;
    },
    forgotPassword() {
      this.$store
        .dispatch("forgotPassword", {
          organizationID: this.$route.params.organizationID,
          email: this.user.email,
        })
        .then(() => {
          this.$store.commit("addToast", {
            type: "success",
            title: "Schau in deine Mails um das Passwort zurückzusetzen.",
          });
        })
        .catch(() => {
          this.$store.commit("addToast", {
            type: "warning",
            title: "Das Passwort konnte nicht zurückgesetzt werden",
          });
        });
    },
    async deleteAccount() {
      await this.$store.dispatch("deleteUser").then(() => {
        this.$router.push(`/${this.$route.params.organizationID}/login`);
      });
    },
    async updateUser() {
      await this.$store.dispatch("updateOwnUser", {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
      });
      this.showEditInfo = false;
    },
  },
  async mounted() {
    await this.$store.dispatch("retrieveOwnUser");
    this.firstName = this.user.firstName;
    this.lastName = this.user.lastName;
    this.email = this.user.email;
  },
};
</script>
