<template>
  <div
    :class="{
      absolute: absolute,
      'rotate--90': rotate,
      'actionButton--accent': accent,
      'actionButton--light': light,
    }"
    @click="(e) => $emit('onClick', e)"
    @keyup.enter="(e) => $emit('onClick', e)"
    class="flex flex--center clickable actionButton circle"
  >
    <i class="font--s2" :class="icon" />
  </div>
</template>
<script>
export default {
  name: "ActionButton",
  props: ["absolute", "icon", "rotate", "accent", "light"],
};
</script>
