<template>
  <div
    :class="{
      'accentButton--filled': filled,
      'accentButton--light': light,
      'width--100': fullWidth,
      'accentButton--disabled': disabled,
    }"
    @click="disabled ? null : $emit('onClick')"
    class="clickable font--s14 font--w700 accentButton flex flex--center"
  >
    <transition name="fade" mode="out-in">
      <div v-if="!this.loading" class="noDec" key="title">
        {{ title }}
      </div>
      <div v-else class="absolutes" key="loader">
        <loader
          :loading="true"
          :color="filled ? '#fff' : '#114B5F'"
          :size="'10px'"
        />
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: "AccentButton",
  props: ["title", "filled", "fullWidth", "disabled", "loading", "light"],
};
</script>
