<template>
  <div class="fullScreen overflowAuto coursesContainer">
    <CoursesHeader />
    <div v-if="isOamn" class="coursesCover">
      <div>Schön, dass Du da bist!</div>
    </div>
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>
<script>
import CoursesHeader from "@/components/views/1_Overview/CoursesHeader";
export default {
  name: "CourseLoader",
  components: { CoursesHeader },
  computed: {
    isOamn() {
      return (
        this.$store.getters.getOrganizationID === "62a8815e42be6e0012bda012"
      );
    },
    organizationID() {
      return this.$store.getters.getOrganizationID;
    },
  },
  mounted() {
    this.$store.dispatch("retrieveCourses", this.$route.params.organizationID);
  },
};
</script>
